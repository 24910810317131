import { Component } from "preact";
import { route } from "preact-router";
import { API_HOST } from "../constants";
import API from "../lib/api";
import CurrentProject from "../lib/project";
import Variables from "../lib/variables";

export default class Footer extends Component {
  componentDidMount() {
    let { templates } = this.state;
    if (!templates) {
      this.api = new API(API_HOST);
      this.getTemplates();
    }

    this._projectChange(CurrentProject.get());
    this.projectChangeBind = this._projectChange.bind(this);
    CurrentProject.listeners.push(this.projectChangeBind);
  }

  componentWillUnmount() {
    CurrentProject.listeners = CurrentProject.listeners.filter(
      l => l !== this.projectChangeBind
    );
  }

  async _projectChange(project) {
    if (!project) {
      return;
    }
    this.api = new API(API_HOST, project);
    this.setState({ versions: [] });
    let versions = await this.api.get(
      "/projects/" + project.slug + "/versions"
    );

    this.setState({ versions, project });
  }

  async resetToVersion(version) {
    this.setState({ versionsMenuOpen: false });
    if (
      !confirm(
        `Are you sure you want to reset to version ${version.version} - ${version.name}?` +
          ` (All the unpublished changes will be lost)`
      )
    )
      return;
    let { project } = this.state;
    await this.api.get(
      "/projects/" + project.slug + "/restore/" + version.version
    );
    alert(
      "Configuration reset to Version: " +
        version.version +
        " - " +
        version.name
    );
    route("/");
  }

  async onPublish() {
    let name = prompt("Version name? (Blank to update current version)");
    if (name == null) {
      //Cancel
      return;
    }
    let { project } = this.state;
    let res;
    try {
      if (!name) {
        res = await this.api.put(
          "/projects/" + project.slug + "/versions/latest"
        );
      } else {
        res = await this.api.post("/projects/" + project.slug + "/publish", {
          name
        });
      }
      this._projectChange(project);
      alert("Your changes have been published.");
    } catch (e) {
      let alertMsg = ["Publish is failed, due to below errors !!!"];
      if (Array.isArray(e.errors)) {
        alertMsg = alertMsg.concat(e.errors);
      } else if (e && e.message) {
        alertMsg = alertMsg.push(e.message);
        console.error(e);
      } else {
        console.error(e);
      }
      alert(alertMsg.join("\n"));
    }
  }

  async deleteProject() {
    let { project } = this.state;
    if (!project) {
      return;
    }

    let res = confirm(
      "Are you sure you want to delete project " + project.name + "?"
    );
    if (!res) {
      return;
    }

    try {
      res = await this.api.delete("/projects/" + project.slug);
      alert("Project " + project.name + " deleted successfully");
    } catch (e) {
      console.error(e);
      alert("Error while deleting project " + project.name);
    }
    this.setState({ project: null });
    CurrentProject.set(null);
    // route("/");
    window.location.href = "/";
  }

  async createTemplate() {
    this.setState({ templatesMenuOpen: false });
    let name = prompt("New Template Name?");
    if (name == null) {
      //Cancel
      return;
    }

    let { project } = this.state;
    try {
      await this.api.post("/template", {
        template_name: name,
        project_slug: project.slug
      });
      alert("Template created: " + name);
      await this.getTemplates();
    } catch (e) {
      alert("Error: " + e.message);
    }
  }

  async getTemplates() {
    let templates = await this.api.get("/template");
    this.setState({ templates });
  }

  async updateTemplate(template) {
    this.setState({ templatesMenuOpen: false });

    let { project } = this.state;
    try {
      await this.api.post("/template/" + template.slug, {
        project_slug: project.slug
      });
      alert("Template Updated: " + template.name);
    } catch (e) {
      alert("Error: " + e.message);
    }
  }

  async applyTemplate(template) {
    this.setState({ templatesMenuOpen: false });

    let { project } = this.state;
    try {
      await this.api.post("/apply-template/" + template.slug, {
        project_slug: project.slug
      });
      alert("Template Applied: " + template.name);
    } catch (e) {
      alert("Error: " + e.message);
    }
    this._projectChange(project);
  }

  async deleteTemplate(template) {
    if (!confirm("Are you sure?")) return;
    this.setState({ templatesMenuOpen: false });

    try {
      await this.api.delete("/template/" + template.slug);
      alert("Template Deleted: " + template.name);
      await this.getTemplates();
    } catch (e) {
      alert("Error: " + e.message);
    }
  }

  async viewTemplate(template) {
    let projects = Variables.get("projects");
    projects = Array.isArray(projects) ? projects : [];
    let templateProject = projects.find(p => {
      return p.slug == template.project_slug;
    });
    if (!templateProject) {
      alert("Project " + template.project_slug + " not found.");
      return;
    }

    CurrentProject.set(templateProject);
  }

  render() {
    let {
      versionsMenuOpen,
      versions,
      templatesMenuOpen,
      templates
    } = this.state;
    let hasVersions = versions && versions.length > 0;
    let hasTemplates = templates && templates.length > 0;
    return (
      <footer className="border-t p-4 fixed left-0 bottom-0 w-full bg-white">
        <div className="container mx-auto flex justify-between items-center">
          <nav className="relative">
            <button
              className={"pl-4 pr-2 py-2 border m-2 border-black"}
              onClick={() => {
                this.setState({
                  templatesMenuOpen: !this.state.templatesMenuOpen
                });
              }}
            >
              Templates &nbsp;
              <span className="fa fa-angle-up mr-2"></span>
            </button>
            {templatesMenuOpen ? (
              <div
                className="absolute w-64 bg-white shadow rounded text-left flex flex-col"
                style={{ bottom: 60, left: 10 }}
              >
                {(templates || []).map(t => (
                  <button className="p-1 m-1 hover:bg-gray-200 block text-left flex justify-between items-center">
                    <div className="w-full">
                      <a
                        title="View Template"
                        onClick={() => {
                          this.viewTemplate(t);
                        }}
                      >
                        <span>{t.name}</span>
                      </a>
                      <div className="float-right">
                        <a
                          title="Update Template"
                          onClick={() => {
                            this.updateTemplate(t);
                          }}
                        >
                          <span class="ti ti-reload mr-2"></span>
                        </a>
                        <a
                          title="Apply Template"
                          onClick={() => {
                            this.applyTemplate(t);
                          }}
                        >
                          <span class="ti ti-download mr-2"></span>
                        </a>
                        <a
                          title="Delete Template"
                          onClick={() => {
                            this.deleteTemplate(t);
                          }}
                        >
                          <span class="ti ti-trash mr-2"></span>
                        </a>
                      </div>
                    </div>
                  </button>
                ))}
                <button
                  className="p-1 m-1 hover:bg-gray-200 block text-left flex justify-between items-center"
                  onClick={() => this.createTemplate()}
                >
                  <div className="w-full">Create New Template</div>
                </button>
              </div>
            ) : null}
          </nav>
          <nav>
            <div className="relative inline-block">
              <button
                className="px-4 py-2 bg-red-700 border border-red-700 text-white m-2"
                onClick={() => this.deleteProject()}
              >
                Delete
              </button>
              <button
                className={
                  "pl-4 pr-2 py-2 border m-2 " +
                  (hasVersions
                    ? "border-black "
                    : "border-gray-600 text-gray-600")
                }
                onClick={() => {
                  this.setState({
                    versionsMenuOpen: !this.state.versionsMenuOpen
                  });
                }}
                disabled={!hasVersions}
              >
                Reset to &nbsp;<span className="fa fa-angle-up mr-2"></span>
              </button>
              {versionsMenuOpen ? (
                <div
                  className="absolute w-64 bg-white shadow rounded text-left flex flex-col"
                  style={{ bottom: 60, right: 0 }}
                >
                  {(versions || []).map(v => (
                    <button
                      className="p-1 m-1 hover:bg-gray-200 block text-left flex justify-between items-center"
                      onClick={() => this.resetToVersion(v)}
                    >
                      <div className="w-full">
                        Version {v.version} - {v.name}
                        <div className="flex justify-between items-center text-xs text-gray-600">
                          <span>Published by: {v.published_by}</span>
                          <span>{v.published}</span>
                        </div>
                      </div>
                      <span className="mr-1"></span>
                    </button>
                  ))}
                </div>
              ) : null}
            </div>
            <button
              className="px-4 py-2 bg-black border border-black text-white m-2"
              onClick={() => this.onPublish()}
            >
              Publish
            </button>
          </nav>
        </div>
      </footer>
    );
  }
}
