const LoginState = {
  listeners: [],
  set: state => {
    LoginState.listeners.forEach(l => l(state));
  },
  get: () => {
    return !!localStorage.getItem("token");
  }
};
export default LoginState;
