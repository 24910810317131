const Variables = {
  set: (key, value) => {
    if (!value) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, JSON.stringify(value));
    }
  },
  get: key => {
    let retValue = null;
    console.log(key);
    try {
      retValue = JSON.parse(localStorage.getItem(key));
    } catch (e) {
      console.log(e);
    }
    return retValue;
  }
};
export default Variables;
