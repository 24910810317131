import { Component, h } from "preact";
import { API_HOST, UI_HOST } from "../constants";
import API from "../lib/api";
import LoginState from "../lib/login_state";
import Loading from "./loading";

export default class Login extends Component {
  componentDidMount() {
    this.api = new API(API_HOST);

    if (window.location.pathname.startsWith("/oauth/callback/")) {
      this.doLogin();
    } else {
      this.initLogin();
    }
  }
  async doLogin() {
    let code = new URL(window.location).searchParams.get("code");
    let slug = window.location.pathname.replace("/oauth/callback/", "");
    history.pushState(null, null, "/");
    try {
      let res = await this.api.post("/login", {
        slug,
        code,
        redirect_uri: this.getOauthRedirectUrl({ slug })
      });

      localStorage.setItem("token", res.token);
      localStorage.setItem("user", JSON.stringify(res.user));
      LoginState.set(true);
    } catch (e) {
      this.initLogin();
    }
  }

  async initLogin() {
    let res = await this.api.get("/login");
    this.setState({ loginURL: this.oauthURL(res) });
  }

  oauthURL(oauthConfig) {
    let loginUrl = new URL(oauthConfig.loginUrl);
    let query = {
      client_id: oauthConfig["clientId"],
      redirect_uri: this.getOauthRedirectUrl(oauthConfig),
      response_type: oauthConfig["responseType"], //"code",
      scope: oauthConfig["scope"] //"openid profile email"
    };
    for (let key in query) {
      loginUrl.searchParams.set(key, query[key]);
    }
    return loginUrl.toString();
  }

  getOauthRedirectUrl(oauthConfig) {
    let path = "/oauth/callback/" + oauthConfig["slug"];
    return UI_HOST + path;
  }

  render() {
    return (
      <div className="flex h-screen items-center justify-center bg-gray-300">
        <div className="w-1/3 p-4 bg-white rounded text-center">
          <h1 className="m-4 text-xl font-bold align-baseline">
            <img src="/assets/logo.png" className="h-16 inline-block" />
          </h1>
          {this.state.loginURL ? (
            <a
              href={this.state.loginURL || "#"}
              className="px-4 py-2 w-full bg-purple-700 text-white inline-block"
            >
              Login with @letsflo email
            </a>
          ) : (
            <Loading />
          )}
        </div>
      </div>
    );
  }
}
