import { Component } from "preact";

import API from "../lib/api";
import { API_HOST } from "../constants";
import Loading from "./loading";
import { Link } from "preact-router/match";
import CurrentProject from "../lib/project";
import Variables from "../lib/variables";

export default class LeftNav extends Component {
  state = { navs: null, projects: null };
  componentDidMount() {
    let project = CurrentProject.get();
    this.api = new API(API_HOST, project);

    this.api.get("/projects").then(projects => {
      if (projects.length < 1) return this.newProject();
      if (!project && projects.length > 0) this.api.project = projects[0];

      Variables.set("projects", projects);
      this.setState({ projects }, () => {
        this.selectProject();
      });
    });
    CurrentProject.listeners.push(proj => {
      this.setState({ currentProject: proj });
    });
  }

  async updateProject() {
    if (!this.state.currentProject) return;
    let project = this.state.currentProject;
    let name = prompt("Project name?", project.name);
    if (name == null) {
      //Cancel Clicked
      return;
    }
    if (name.trim() == "") {
      //Empty ok
      alert("Invalid project name");
      return;
    }
    let res = await this.api.put("/projects/" + project.slug, { name });

    this.setState(
      {
        projects: this.state.projects.map(p =>
          p.slug === project.slug ? res : p
        )
      },
      () => {
        this.selectProject(res.slug);
      }
    );
  }

  async newProject() {
    let name = prompt("Project name?");
    if (name == null) {
      //Cancel Clicked
      return;
    }
    if (name.trim() == "") {
      //Empty ok
      alert("Invalid project name");
      return;
    }
    let res = await this.api.post("/projects", { name });
    this.setState({ projects: [...this.state.projects, res] }, () => {
      this.selectProject(res.slug);
    });
  }

  selectProject(v) {
    if (v) {
      let project = (this.state.projects || []).find(p => p.slug === v);
      CurrentProject.set(project);
      this.setState({ currentProject: project });
      return;
    }
    let currentProject = CurrentProject.get();
    if (
      !currentProject &&
      this.state.projects &&
      this.state.projects.length > 0
    ) {
      CurrentProject.set(this.state.projects[0]);
      currentProject = this.state.projects[0];
    }
    this.api.project = currentProject;
    this.api.get("/navs").then(navs => this.setState({ navs, currentProject }));
  }

  render() {
    let { navs, projects } = this.state;
    return (
      <div className="bg-white rounded h-full p-2 navigation">
        <h3 className="text-gray-600 py-2">Project</h3>
        {projects ? (
          <div className="text-xl w-full">
            <select
              className="w-2/3 border rounded p-2"
              onChange={e => this.selectProject(e.srcElement.value)}
            >
              {projects.map(proj => (
                <option
                  value={proj.slug}
                  selected={
                    this.state.currentProject &&
                    this.state.currentProject.slug === proj.slug
                  }
                >
                  {proj.name}
                </option>
              ))}
            </select>
            <Link href={"/"} className="ml-2">
              <span className="ti ti-pencil"></span>
            </Link>
            <button className="ml-2" onClick={e => this.newProject()}>
              <span className="ti ti-plus"></span>
            </button>
          </div>
        ) : (
          <Loading></Loading>
        )}
        <h3 className="mt-2 text-gray-600 py-2">Navigation</h3>
        {(navs || []).map(form => {
          if (!form.hide_nav) {
            return (
              <Link
                href={"/" + form.key}
                activeClassName="bg-gray-200"
                className="my-1 py-2 px-4 hover:bg-gray-200 text-gray-800 text-lg cursor-pointer block"
              >
                <span className={"fa fa-" + form.icon + " mr-2"}></span>
                {form.label}
              </Link>
            );
          }
        })}
      </div>
    );
  }
}
