import LoginState from "./login_state";

class API {
  constructor(host, project) {
    // if (!project) {
    //   throw new Error("Project is not defined.");
    // }
    this.host = host;
    this.project = project;
  }

  async get(path) {
    return this._call(path, "GET");
  }

  async post(path, body) {
    return this._call(path, "POST", body);
  }
  async put(path, body) {
    return this._call(path, "PUT", body);
  }
  async delete(path) {
    return this._call(path, "DELETE");
  }

  async _call(path, method, body, headers = {}) {
    let token = localStorage.getItem("token");
    if (!headers["Content-Type"]) {
      headers["Content-Type"] = "application/json";
    }
    if (token) headers["Authorization"] = "Bearer " + token;

    if (this.project) headers["project"] = this.project.slug;
    if (body) {
      body = JSON.stringify(body);
    }
    let res = await fetch(this.host + path, {
      method,
      headers,
      body
    });
    if (res.status !== 200) {
      if (res.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        LoginState.set(false);
      } else if (res.status === 400) {
        let resJson = await res.json();
        let e = new Error(resJson.message);
        e.errors = resJson.errors;
        throw e;
      }

      let e = new Error(res.statusText);
      e.data = await res.json();
      throw e;
    }
    res = await res.json();
    return res;
  }
}
export default API;
