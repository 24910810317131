const CurrentProject = {
  listeners: [],
  set: project => {
    if (!project) {
      localStorage.removeItem("project");
    } else {
      localStorage.setItem("project", JSON.stringify(project));
    }
    CurrentProject.listeners.forEach(l => l(project));
  },
  get: () => {
    return JSON.parse(localStorage.getItem("project"));
  }
};
export default CurrentProject;
